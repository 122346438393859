import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import { PageProps } from "gatsby";
import Seo from "../components/Seo";
import { Breadcrumb } from "@cncxt/imodium-component-library";
import TacoList from "../components/TacoList";
import HeroBanner from "../components/Banner";
import RichTextComp from "../components/RichTextComp";
import AccordionList from "../components/AccordionList";
import styles from "../styles/articlePage.module.scss";

interface pageInterface extends PageProps {
  pageContext: {
    uuid: string;
    urlPath: string;
    title: string;
    pageType: string;
    components: string;
    footerData: string;
    meta: {
      property_key_name: string;
      property_key_value: string;
      content: string;
    }[];
    canonical: {
      ref: string;
      href: string;
    };
    hreflang: {
      rel: string;
      hreflang: string;
      href: string;
    }[];
    breadcrumbs: {
      title: string;
      link: string;
    }[];
  };
}

const ArticlePage = (data: pageInterface): JSX.Element => {
  const dataContext = data.pageContext;
  const pathname = data.location.pathname;
  const pageTitle = dataContext.title;
  const footerData = dataContext.footerData;
  const componentData = JSON.parse(dataContext.components);
  const breadcrumbList =
    dataContext.breadcrumbs !== null
      ? dataContext.breadcrumbs.map(path => {
          return { label: path.title, link: path.link };
        })
      : [];
  const heroBanner = componentData.find(
    component => component.type === "hero_banner",
  );
  const richTextArray = componentData.filter(
    component => component.type === "rich_text",
  );
  const tacoList = componentData.find(
    component => component.type === "taco_list",
  );
  const qaList = componentData.find(component => component.type === "qa");
  return (
    <DefaultLayout siteFooterData={footerData}>
      <Seo
        metaArray={dataContext.meta}
        canonicalArray={dataContext.canonical}
        hreflangArray={dataContext.hreflang}
      />
      <div style={{ padding: "0 16px" }}>
        {dataContext.breadcrumbs !== null && pathname !== "/" && (
          <Breadcrumb listItems={breadcrumbList} variation={"linear"} />
        )}
        {heroBanner && (
          <HeroBanner
            content={heroBanner.content}
            width={heroBanner.width}
            shareTitle={pageTitle}
          />
        )}
        <div className={styles.mainSection}>
          <div className={styles.richtextWrapper}>
            {richTextArray[0] && (
              <RichTextComp content={richTextArray[0].content} width="100%" />
            )}
            {qaList && (
              <AccordionList
                content={qaList.content}
                width="100%"
                pathname={pathname}
              />
            )}
          </div>
          {tacoList && (
            <div className={styles.faqTacoWrapper}>
              <TacoList data={tacoList.content} width="100%" />
            </div>
          )}
        </div>

        {richTextArray[1] && (
          <RichTextComp content={richTextArray[1].content} width="100%" />
        )}
      </div>
    </DefaultLayout>
  );
};

export default ArticlePage;
